<template>
	<section>
		<!-- Keep the page amount and page number on top. Scrolling through 500 items to change the amount back isn't fun -->

		<b-table :sort-compare="func" tbody-tr-class="trTable" ref="table" :responsive="true" :busy="isBusy"
			:current-page="currentPage" caption-top :per-page="perPage" :striped="striped" :bordered="bordered"
			:borderless="borderless" :outlined="outlined" :small="small" :hover="hover" :dark="dark" :fixed="fixed"
			:foot-clone="footClone" :no-border-collapse="noCollapse" :items="items" :fields="fields" :head-variant="headVariant"
			:table-variant="tableVariant" stacked="md" @row-clicked="openModal" :sort-by.sync="sortBy"
			:sort-desc.sync="sortDesc">
			<template v-for="(x, slotName) in $scopedSlots" v-slot:[slotName]="context">
				<slot :name="slotName" v-bind="context" />
			</template>

			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong> Loading...</strong>
				</div>
			</template>
		</b-table>
		<b-row>
			<div class="col-lg-2 col-auto">
				<b-form-group label-cols="auto" label-size="sm" label-for="perPageSelect" class="mb-0">
					<template slot="label">Antal per side</template>
					<b-form-select v-model="perPage" id="perPageSelect" size="sm" :options="pageOptions"></b-form-select>
				</b-form-group>
			</div>

			<div class="col-auto ml-auto">
				<b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
					class="my-0"></b-pagination>
			</div>
		</b-row>
	</section>
</template>
<script>
export default {
	name: 'BaseTable',
	mounted() { },
	data() {
		return {
			currentPage: 1,
			perPage: 30,
			pageOptions: [30, 50, 100, 500, 5000],
			striped: false,
			bordered: false,
			borderless: true,
			outlined: true,
			small: true,
			hover: true,
			dark: false,
			footClone: false,
			headVariant: 'none',
			tableVariant: 'light',
			noCollapse: false,
		}
	},
	props: { items: Array, fields: Array, isBusy: Boolean, fixed: { type: Boolean, default: false }, func: Function, sortBy: String, sortDesc: Boolean },
	methods: {

		openModal(data) {
			this.$emit('on-click', data)
		},
	},
	watch: {
		perPage() {
			this.$emit('perPageChanged', this.perPage)
		},
		currentPage() {
			this.$emit('currentPageChanged', this.currentPage)
		},
	},
	computed: {
		totalRows() {
			if (!this.items) {
				return 1
			}
			return this.items.length
		},
	},
}
</script>
<style lang="scss"></style>
