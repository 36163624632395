const { DateTime } = require('luxon')

const defaultLanguage = 'da'

const dateMixin = {
	methods: {
		getDateAndMonth(date) {
			if (date) {
				var obj = DateTime.fromISO(date.toISOString())
				return obj.setLocale(defaultLanguage).toLocaleString(DateTime.DATE_FULL) //https://moment.github.io/luxon/docs/manual/formatting.html}
			} else return null
		},
		getDateAndTime(date) {
			var obj = DateTime.fromISO(date.toISOString())
			return obj.setLocale(defaultLanguage).toLocaleString(DateTime.DATETIME_SHORT) //https://moment.github.io/luxon/docs/manual/formatting.html
		},
	},
}

export default dateMixin
